import LogoImage from "common/components/logo-image";
import MeshBg from "common/images/bg.png";

const BgLayout = ({ children, admin = false }) => {
  return (
    <main className="min-h-screen w-full flex flex-col items-center justify-center mobile:justify-start relative py-12 mobile:py-8 mobile:bg-white">
      {/* mesh bg */}
      <img
        src={MeshBg}
        alt="ReLease logo background pattern"
        className="absolute top-0 left-0 w-full h-full z-10 object-cover mobile:hidden"
      />

      {/* container */}
      <section className="w-fit max-w-3xl flex flex-col justify-center items-center gap-12 relative z-20">
        {/* desktop logo */}
        <div className="flex flex-col gap-2 mobile:hidden">
          <LogoImage dark={true} />
          {admin && (
            <span className="uppercase font-semibold tracking-wider">
              Admin Portal
            </span>
          )}
        </div>

        {/* white bg */}
        <div className="w-fit h-full rounded-xl bg-white p-12 mobile:px-8 flex flex-col items-center justify-center gap-12">
          {/* mobile logo */}
          <div className="mobile:flex flex-col gap-2 hidden">
            <LogoImage dark={true} />
            {admin && (
              <span className="uppercase font-semibold tracking-wider">
                Admin Portal
              </span>
            )}
          </div>

          {/* children elements */}
          {children}
        </div>
      </section>
    </main>
  );
};

export default BgLayout;
