// Deprecating this terrible landing page in favor of a more simple redirect to signup/login in the style of the dashboard-welcome page
// import { useNavigate } from 'react-router-dom';
// import Button from 'common/components/button';
// import LogoImage from 'common/components/logo-image';
// 
// const LandingPage = () => {
//   // DATA INITIALIZATION
//   const navigate = useNavigate();
//   const pricingData = [
//     {
//       screnarios: 'Have health challenges in your first month',
//       costWithoutCios: '10K',
//       costWithCios: 'Zero Cost',
//     },
//     {
//       screnarios: 'Job offer in a new city',
//       costWithoutCios: '6K',
//       costWithCios: 'Zero Cost',
//     },
//     {
//       screnarios: 'Have to drop out of school',
//       costWithoutCios: '6K',
//       costWithCios: 'Zero Cost',
//     },
//     {
//       screnarios: 'If you relocate for work',
//       costWithoutCios: '10K',
//       costWithCios: 'Zero Cost',
//     },
//     {
//       screnarios: 'If your job gets rid of remote work',
//       costWithoutCios: '6K',
//       costWithCios: 'Zero Cost',
//     },
//     {
//       screnarios: 'If you lose your current job',
//       costWithoutCios: '6K',
//       costWithCios: 'Zero Cost',
//     },
//     {
//       screnarios: 'If you buy your first home',
//       costWithoutCios: '10K',
//       costWithCios: 'Zero Cost',
//     },
//     {
//       screnarios: 'If you legally separate or get divorced',
//       costWithoutCios: '6K',
//       costWithCios: 'Zero Cost',
//     },
//     {
//       screnarios: 'If you get married or enter a civil union',
//       costWithoutCios: '6K',
//       costWithCios: 'Zero Cost',
//     },
//   ];
// 
//   return (
//     <main className='h-full overflow-hidden w-full flex flex-col items-center justify-start relative mobile:bg-white'>
//       {/* jumbotron section */}
//       <section
//         className='w-full flex flex-col px-[7rem] gap-[7rem] z-20 items-start relative py-[5%] mobile:px-7 mobile:gap-24 mobile:py-[3rem]'
//         style={{ backgroundImage: 'url(/images/dashboard/bg-vec.svg)' }}
//       >
//         {/* logo */}
//         <LogoImage className='z-20' />

//         {/* container */}
//         <div className='w-full flex flex-col items-start gap-4 relative z-20'>
//           {/* did you know button */}
//           <Button
//             theme='secondary'
//             style={{
//               borderColor: 'black',
//               width: 'auto',
//               paddingInline: '30px',
//             }}
//             title='Did you know'
//           />

//           {/* texts adn image information */}
//           <div className='w-full flex gap-5 mobile:flex-col mobile:gap-20'>
//             {/* text and button section */}
//             <div className='w-full flex flex-col items-start gap-6'>
//               <h2 className='font-bold leading-[78px] text-[64px] mobile:text-[36px] mobile:leading-[48px] text-[#191923]'>
//                 It could cost you
//                 <br className='mobile:hidden' /> $10,000 in fees to
//                 <br className='mobile:hidden' /> move out early.
//               </h2>

//               {/* button */}
//               <div className='flex mobile:flex-col w-full'>
//                 <Button
//                   onClick={() => {
//                     navigate('/login');
//                   }}
//                   title='Get Covered Today'
//                   style={{ width: 'auto', paddingInline: '64px' }}
//                 />
//               </div>
//             </div>

//             {/* image section */}
//             <div
//               className='flex flex-col w-fit h-fit p-6 mobile:p-4 rounded-3xl gap-2 mobile:w-full'
//               style={{
//                 backdropFilter: 'blur(2.5px)',
//                 background:
//                   'linear-gradient(0deg, rgba(233, 241, 255, 0.5), rgba(233, 241, 255, 0.5)), linear-gradient(180deg, #C4DFFF 0%, #C4DFFF 0.01%, #FAD8FF 100%)',
//               }}
//             >
//               {/* title text */}
//               <h3 className='text-[#191923] leading-[72px] font-bold text-[60px] mobile:text-[56px] mobile:leading-[66px]'>
//                 <span className='text-[#9747FF]'>100%</span>
//                 <br />
//                 Lease <br />
//                 Coverage
//               </h3>

//               {/* image */}
//               <div className='flex w-full gap-3 items-center'>
//                 <img
//                   src='/images/dashboard/coverage-users.png'
//                   alt='coverage'
//                   className='h-[39px]'
//                 />
//                 <h5 className='text-[#191923] font-medium text-sm'>
//                   Used by <br />
//                   5,000 & more
//                 </h5>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* pricing table section */}
//       <section className='w-full flex flex-col px-[7rem] gap-20 items-center relative z-20 py bg-white py-[5rem] overflow-hidden mobile:px-0'>
//         {/* bg mesh */}
//         <img
//           src='/images/dashboard/pricing-table-mesh.svg'
//           className='absolute top-[-15dvh] left-[-6dvw] z-[15] mobile:hidden'
//           alt=''
//         />

//         {/* header texts */}
//         <div className='w-fit mobile:w-full flex items-center text-center flex-col gap-1 mobile:px-5'>
//           <h4 className='text-lg font-semibold text-[#191923]'>
//             Pricing Table
//           </h4>
//           <h2 className='font-bold text-5xl mobile:text-[32px] text-[#191923]'>
//             Why You Need Cios
//           </h2>
//           <h5 className='font-normal text-base text-[#6A6C70]'>
//             There are many reason why you need a Lease Flexibility with Cios.
//           </h5>
//         </div>

//         {/* pricing table */}
//         <div className='w-full flex flex-col'>
//           <table className='w-[80%] mobile:w-full mx-auto'>
//             {/* table header */}
//             <thead className='grid grid-cols-[50%_25%_25%] px-5 py-4 border-[#19192352] border-b mobile:items-center'>
//               <th className='text-[#191923] font-semibold text-base text-left'>
//                 Scenarios
//               </th>
//               <th className='text-[#191923] font-semibold text-base text-center'>
//                 Cost Without Cios
//               </th>
//               <th className='text-[#191923] font-semibold text-base text-right'>
//                 Cost With Cios
//               </th>
//             </thead>

//             {/* table body */}
//             {pricingData.map((priceInfo, index) => (
//               <tbody
//                 className='grid grid-cols-[50%_25%_25%] px-5 py-4 border-[#19192352] border-b'
//                 key={index}
//               >
//                 <td className='text-[#191923] font-medium text-base text-left'>
//                   {priceInfo.screnarios}
//                 </td>
//                 <td className='text-[#191923] font-medium text-base text-center'>
//                   {priceInfo.costWithoutCios}
//                 </td>
//                 <td className='text-[#191923] font-medium text-base text-right'>
//                   {priceInfo.costWithCios}
//                 </td>
//               </tbody>
//             ))}
//           </table>
//         </div>

//         {/* Fotter section */}
//         <footer className='w-[80%] mx-auto flex items-end gap-2 justify-between mobile:w-full mobile:flex-col mobile:px-7 mobile:gap-16'>
//           {/* texts and button container */}
//           <div className='w-fit flex flex-col gap-3 items-start mobile:w-full'>
//             <h2 className='font-bold text-4xl text-[#212B36]'>
//               Get Started Today
//             </h2>
//             <h3 className='font-normal text-base text-[#637381]'>
//               Lease break coverage gives you the complete freedom to move when{' '}
//               <br />
//               you need to, without fear of expensive lease break penalties.
//             </h3>
//             {/* button */}
//             <div className='flex mobile:flex-col w-full'>
//               <Button
//                 onClick={() => {
//                   navigate('/dashboard/apply-for-plans');
//                 }}
//                 title='Get Covered Today'
//                 style={{
//                   width: 'auto',
//                   paddingInline: '64px',
//                   marginTop: '12px',
//                 }}
//               />
//             </div>
//           </div>

//           {/* partners container */}
//           <div className='w-fit flex flex-col gap-3 items-center'>
//             <h3 className='font-medium text-base text-[#191923]'>
//               Our Partners
//             </h3>

//             {/* partners logo */}
//             <img
//               alt='partners logo'
//               src='/images/dashboard/partners.png'
//               className='w-[486px]'
//             />
//           </div>
//         </footer>
//       </section>
//     </main>
//   );
// };

import { Link } from 'react-router-dom';
import Button from 'common/components/button';
import BgLayout from 'common/components/bg';
import Tracker from '../../components/auth/welcome/tracker';

const LandingPage = () => {
  return (
    <>
      <BgLayout>
        {/* texts */}
        <div className='flex flex-col text-center gap-8'>
          <h2 className='font-bold text-4xl'>
            Flexibility to move out any time, penalty-free
          </h2>

          <p className='text-lg font-normal'>
            When you're covered by ReLease you can rent worry-free, with the flexibility to move out any time, penalty-free
          </p>
        </div>

        {/* button */}
        <div className='flex w-full flex-col gap-7'>
          <Link to='/signup' className='w-full'>
            <Button title='Sign Up For ReLease' linkId='signUpBtn' />
          </Link>
          <Link to='/login' className='w-full'>
            <Button theme='secondary' title='Log Into Your Account' linkId='logInBtn' />
          </Link>
        </div>
      </BgLayout>
      <Tracker />
    </>
  );
};

export default LandingPage;
