import { statusEnum } from 'utils/enums';

const StatusLabel = ({
  status = 'In Review',
  size = 'base',
  isMobile = false,
  addedText,
}) => {
  // DATA INITIALIZATION
  let containerSizeStyle = '';
  let containerTextBgStyles = '';
  let circleStyles = '';

  switch (size) {
    case 'lg':
      containerSizeStyle = 'text-lg px-4';
      break;
    case 'base':
      containerSizeStyle = 'text-base px-3';
      break;
    case 'sm':
      containerSizeStyle = 'text-sm px-3';
      break;
    case 'xs':
      containerSizeStyle = 'text-xs px-2';
      break;
    default:
      containerSizeStyle = 'text-base px-3';
  }
  switch (status) {
    // PLANS
    case statusEnum.created:
      containerTextBgStyles = 'text-[#9D5425] bg-[#FBBF241A]';
      circleStyles = 'bg-[#FBBF24]';
      break;
    case statusEnum.review:
      containerTextBgStyles = 'text-[#9D5425] bg-[#FBBF241A]';
      circleStyles = 'bg-[#FBBF24]';
      break;
    case statusEnum.complete:
      containerTextBgStyles = 'text-[#34D399] bg-[#34D39926]';
      circleStyles = 'bg-[#34D399]';
      break;
    case statusEnum.cancelled:
      containerTextBgStyles = 'text-[#BC1C21] bg-[#FEEAEA]';
      circleStyles = 'bg-[#F87171]';
      break;
    case statusEnum.rejected:
      containerTextBgStyles = 'text-[#BC1C21] bg-[#FEEAEA]';
      circleStyles = 'bg-[#F87171]';
      break;
    case statusEnum.pending:
      containerTextBgStyles = 'text-[#9747FF] bg-[#9747FF26]';
      circleStyles = 'bg-[#9747FF]';
      break;
    case statusEnum.active:
      containerTextBgStyles = 'text-[#34D399] bg-[#34D39926]';
      circleStyles = 'bg-[#34D399]';
      break;
    case statusEnum.paid:
      containerTextBgStyles = 'text-[#34D399] bg-[#34D39926]';
      circleStyles = 'bg-[#34D399]';
      break;

    // CLAIMS
    case statusEnum.Completed:
      containerTextBgStyles = 'text-[#34D399] bg-[#34D39926]';
      circleStyles = 'bg-[#34D399]';
      break;
    case statusEnum.Approved:
      containerTextBgStyles = 'text-[#34D399] bg-[#34D39926]';
      circleStyles = 'bg-[#34D399]';
      break;
    case statusEnum.Confirmed:
      containerTextBgStyles = 'text-[#34D399] bg-[#34D39926]';
      circleStyles = 'bg-[#34D399]';
      break;
    case statusEnum.Expired:
      containerTextBgStyles = 'text-[#BC1C21] bg-[#FEEAEA]';
      circleStyles = 'bg-[#F87171]';
      break;
    case statusEnum.Denied:
      containerTextBgStyles = 'text-[#BC1C21] bg-[#FEEAEA]';
      circleStyles = 'bg-[#F87171]';
      break;
    case statusEnum.Draft:
      containerTextBgStyles = 'text-gray-600 bg-gray-200';
      circleStyles = 'bg-gray-400';
      break;
    case statusEnum.inReview:
      containerTextBgStyles = 'text-[#9D5425] bg-[#FBBF241A]';
      circleStyles = 'bg-[#FBBF24]';
      break;
    case statusEnum.submitted:
      containerTextBgStyles = 'text-[#9D5425] bg-[#FBBF241A]';
      circleStyles = 'bg-[#FBBF24]';
      break;
    case statusEnum.Resolved:
      containerTextBgStyles = 'text-[#34D399] bg-[#34D39926]';
      circleStyles = 'bg-[#34D399]';
      break;

    // DEFAULT
    default:
      containerTextBgStyles = 'text-gray-600 bg-gray-200';
      circleStyles = 'bg-gray-400';
  }

  const statusText = (addedText ? `${status} (${addedText})` : status)

  if (isMobile) {
    return (
      <div
        className={`inline-flex w-fit py-[3px] capitalize ${containerSizeStyle} ${containerTextBgStyles} font-normal items-center gap-2 rounded-2xl`}
      >
        <span className={`h-[12px] w-[12px] ${circleStyles} rounded-full`} />
        <span className='font-medium'>{statusText}</span>
      </div>
    );
  } else {
    return (
      <div
        className={`inline-flex w-fit py-[3px] capitalize ${containerSizeStyle} ${containerTextBgStyles} font-normal items-center gap-2 rounded-2xl`}
      >
        <span className={`h-[12px] w-[12px] ${circleStyles} rounded-full`} />
        <span className='font-medium'>{statusText}</span>
      </div>
    );
  }
};

export default StatusLabel;
