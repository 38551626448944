import LogoImage from 'common/components/logo-image';

const AuthAsideLayout = () => {
  return (
    <aside className='h-full w-[30%] flex justify-center relative pt-[5%] mobile:hidden'>
      {/* mesh bg */}
      <img
        src='/images/login/side-bg.png'
        alt='aside mesh bg'
        className='absolute top-0 left-0 w-full h-full z-10 object-cover'
      />

      {/* wrapper */}
      <div className='flex flex-col gap-16 relative z-20 w-[80%]'>
        <LogoImage dark={true} />

        {/* texts */}
        <div className='flex flex-col gap-7'>
          <h1 className='font-bold text-4xl'>
            Flexibility to move out any time, penalty-free
          </h1>

          <p className='text-lg font-normal'>
            When you're covered by ReLease you can rent worry-free, with the flexibility to move out any time, penalty-free
          </p>
        </div>
      </div>
    </aside>
  );
};

export default AuthAsideLayout;
